// Webpack Imports
import * as bootstrap from 'bootstrap';


( function () {
	'use strict';

	// Focus input if Searchform is empty
	[].forEach.call( document.querySelectorAll( '.search-form' ), ( el ) => {
		el.addEventListener( 'submit', function ( e ) {
			var search = el.querySelector( 'input' );
			if ( search.value.length < 1 ) {
				e.preventDefault();
				search.focus();
			}
		} );
	} );

	// Initialize Popovers: https://getbootstrap.com/docs/5.0/components/popovers
	/*var popoverTriggerList = [].slice.call( document.querySelectorAll( '[data-bs-toggle="popover"]' ) );
	var popoverList = popoverTriggerList.map( function ( popoverTriggerEl ) {
		return new bootstrap.Popover( popoverTriggerEl, {
			trigger: 'focus',
		} );
	} );*/
	//Use vanilla JS code from here for frontend. Following is example about how to use it.
	
	window.onscroll = function() {clarityOnScroll()};
	function clarityOnScroll() {
		if (document.body.scrollTop > 35 || document.documentElement.scrollTop > 35) {
			document.body.classList.add('sticky-enable');
			document.getElementById("site-header").classList.add('sticky-header');
			document.getElementById('header').classList.add('fixed-top');
		} else {
			document.body.classList.remove('sticky-enable');
			document.getElementById("site-header").classList.remove('sticky-header');
			document.getElementById('header').classList.remove('fixed-top');
		}
	}
	var toastElList = [].slice.call(document.querySelectorAll('.toast'))
	var toastList = toastElList.map(function (toastEl) {
	return new bootstrap.Toast(toastEl, {'animation':true})
	});
} )();

(function ($) {
	/**Jquery based actions can call here */
	$(document).on('click','.navbar-toggler',function(e){
		e.preventDefault();
		if($('#navbar').hasClass('show')){
			$('#navbar').removeClass('show');
		}
		else{
			$('#navbar').addClass('show');
		}
	});
	$(document).on('click', '#menu-main-menu > li.menu-item-has-children > a', function (e) {
		e.stopPropagation();
		if(!$(this).hasClass('clicked')){
            e.preventDefault();
            $('#menu-main-menu > li.menu-item-has-children.active').each(function () {
				$(this).removeClass('active');
                $(this).find('ul').removeClass('show');
                $(this).find('a.clicked').removeClass('clicked');
			});
            $(this).parent().find('ul').addClass('show');
            $(this).addClass('clicked');
            $(this).parent().addClass('active');
        }
	});
	$(document).on('click',function() {
		$('#menu-main-menu > li.menu-item-has-children.active').each(function () {
			$(this).removeClass('active');
			$(this).find('ul').removeClass('show');
			$(this).find('a.clicked').removeClass('clicked');
		});
	});
	$(function() {	
		if($('.push-notification-roator').length){
			$('.push-notification-roator').slick({
				infinite: true,
				speed: 1000,
				slidesToShow: 1,
				slidesToScroll: 1,
				dots: false,
				rows:0,
				variableWidth: false,
				autoplay: true,
				autoplaySpeed: 30000,
				arrows:false,
				fade: true,
				adaptiveHeight: true,
  				cssEase: 'linear'
			});
		}
		$('.logos-carousel').slick({
			infinite: true,
			speed: 2500,
			slidesToShow: 7,
			slidesToScroll: 1,
			dots: false,
			rows:0,
			variableWidth: false,
			autoplay: true,
			autoplaySpeed: 0,
			arrows:false,
			cssEase: "linear",
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 6,
					}
				},
				{
					breakpoint: 1000,
					settings: {
						slidesToShow: 4,
						variableWidth: false,
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 3,
						variableWidth: false,
					}
				},
				{
					breakpoint: 576,
					settings: {
						slidesToShow: 2,
						variableWidth: false,
					}
				},
				{
					breakpoint: 320,
					settings: {
						slidesToShow: 1
					}
				},      
			]
		});
		$(document).on('click','.cl-filter-widgets .widget-title',function(e){
			e.preventDefault();
			if($(this).hasClass('active')){
				$(this).next('ul').removeClass('active');
				$(this).removeClass('active');
			}
			else{
				$('.cl-filter-widgets').find('.widget-title.active').removeClass('active');
				$('.cl-filter-widgets').find('ul.active').removeClass('active');
				$(this).next('ul').addClass('active');
				$(this).addClass('active');
			}			
		});
		$(document).on('click','.cl-filter-widgets ul a',function(e){
			e.preventDefault();
			$( '.cl-filter-container' ).addClass('clarity-loading').html('<div class="cl-ajax-loading"><i class="far fa-spin fa-spinner"></i></div>');
			$( '.cl-filter-widgets' ).html('<div class="cl-ajax-loading"><i class="far fa-spin fa-spinner"></i></div>');
			$('.cl-filter-widgets').removeClass('active');
			var href = $(this).attr('href');
			$('html, body').animate({
				scrollTop: $('.cl-filter-container').offset().top - 136
			}, 500);
			$.ajax( {
				url: href,
				success( response ) {
					$( '.cl-filter-container').removeClass( 'clarity-loading' );
					//container
					if ($( response ).find( '.cl-filter-container' ).length > 0) {
						$( '.cl-filter-container' ).html($( response ).find( '.cl-filter-container' ).html());
						$( '.cl-filter-widgets' ).html($( response ).find( '.cl-filter-widgets' ).html());
					} else {
						$( '.cl-filter-container' ).html($( response ).find( '.woocommerce-info' ));
					}
					//update browser history (IE doesn't support it)
					window.history.pushState(
						{ pageTitle: response.pageTitle },
						'',
						href
					);
				},
			} );
		});
		$(document).on('click','.mobile-filter-btn',function(e){
			e.preventDefault();
			$( '.cl-filter-widgets' ).addClass('active');
		});
		$(document).on('click','.close-filter',function(e){
			e.preventDefault();
			$( '.cl-filter-widgets' ).removeClass('active');
		});
		
		setTimeout(function() {
			$('.woocommerce-message').fadeOut('fast') 
		}, 5000);

		$('.related.products').find('.products').slick({
			infinite: false,
			speed: 300,
			slidesToShow: 3,
			slidesToScroll: 1,
			dots: false,
			rows:0,
			variableWidth: false,
			autoplay: false,
			arrows:true,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 2
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1
					}
				},    
			]
		});
	});
})(jQuery);